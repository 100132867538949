import React, {Fragment, useEffect} from 'react'
import axios from 'axios'
import './ZasilkyList.css'
import {apiKeys} from "../../apiKeys";

function ZasilkyPacketaList() {
    const [UserData, SetUserData] = React.useState([])
    const [UserDataResult, SetUserDataResult] = React.useState([])
    const [UserNactiButton, SetUserNactiButton] = React.useState([])

    // const [ZasilkyKTiskuData, SetZasilkyKTiskuData] = React.useState([])
    // const [ZasilkyKTiskuDataResult, SetZasilkyKTiskuDataResult] = React.useState([])
    // const [ZasilkyKTiskuButton, SetZasilkyKTiskuButton] = React.useState([])

    const [error, setError] = React.useState(null);
    // var txtNadpis = "Načti"
    // if(apiKeys.appTarget == "ppl")
    //     txtNadpis = apiKeys.txtNadpisPPL;
    // else
    //     txtNadpis = apiKeys.txtNadpis;


    // function buttonPdfZeZasilkova(e) {
    //     e.preventDefault();
    //     fetchPdffromPPL();
    // }

    // function buttonZasilkyPacketaZPohodyKTisku(e) {
    //     e.preventDefault();
    //     // var myBlock= document.getElementById("NactiZasilkyBlock");
    //     SetZasilkyKTiskuData(null);
    //     SetZasilkyKTiskuDataResult(false);
    //     SetZasilkyKTiskuButton(true);
    //
    //     // myBlock.remove();
    //     fetchZasilkyPPLTiskuzPohody();
    // }

    function buttonZasilkyPacketaNepodanezPohody(e) {
        e.preventDefault();
        // console.log("buttonZasilkyPacketaNepodanezPohody");
        // var myBlock= document.getElementById("NactiZasilkyBlock");
        SetUserData(null);
        SetUserDataResult(false);
        SetUserNactiButton(false);

        // myBlock.remove();
        fetchZasilkyPPLNepodanezPohody();
    }

    function buttonZasilkyPPLzPohodyZapis(e) {
        e.preventDefault();
        console.log("buttonZasilkyPPLPohodyZapis");
        SetUserData(null);
        SetUserDataResult(false);
        SetUserNactiButton(false);
        let filename;
        try {
            axios.get(`${apiKeys.base}/apipohodaimege/getzasilkynepodanezpohodywritetoppl`,{responseType: 'blob'})
        .then(response => {
                const disposition = response.headers['content-disposition'];
                filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
                if (filename.toLowerCase().startsWith("utf-8''"))
                    filename = decodeURIComponent(filename.replace("utf-8''", ''));
                else
                    filename = filename.replace(/['"]/g, '');
                return response.data;
            }).then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a); // append the element to the dom
                a.click();
                a.remove(); // afterwards, remove the element
                fetchZasilkyPPLNepodanezPohody();
            }).catch(error => {
                    console.error(error);
                });
        } catch (error) {
            setError({message: 'Not Found'});
        }




    }

    // const delay = ms => new Promise(
    //     resolve => setTimeout(resolve, ms)
    // );
    // const fetchPdffromPPL = async () => {
    //     // console.log("fetchPdffromPacketa run");
    //     SetUserData(null);
    //     SetUserDataResult(false);
    //     SetUserNactiButton(false);
    //     // SetZasilkyKTiskuData(null);
    //     // SetZasilkyKTiskuDataResult(false);
    //     // SetZasilkyKTiskuButton(false);
    //
    //     try {
    //         let filename;
    //         // {responseType:'pdf'}
    //         axios.get(`${apiKeys.base}/apipohodaimege/getpdffrompacketa`, {responseType: 'blob'})
    //             .then(response => {
    //
    //             const disposition = response.headers['content-disposition'];
    //
    //             filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
    //             if (filename.toLowerCase().startsWith("utf-8''"))
    //                 filename = decodeURIComponent(filename.replace("utf-8''", ''));
    //             else
    //                 filename = filename.replace(/['"]/g, '');
    //             return response.data;
    //         }).then(blob => {
    //                 var url = window.URL.createObjectURL(blob);
    //                 var a = document.createElement('a');
    //                 a.href = url;
    //                 a.download = filename;
    //                 document.body.appendChild(a); // append the element to the dom
    //                 a.click();
    //                 a.remove(); // afterwards, remove the element
    //             })
    //             .catch(error => {
    //                 console.error(error);
    //             });
    //
    //
    //     } catch (error) {
    //         setError({message: 'Not Found'});
    //     }
    //     // return data;
    //     fetchZasilkyPPLNepodanezPohody();
    //     // delay(20000).then(() => {fetchZasilkyPacketaTiskuzPohody() });
    // }
    // const fetchZasilkyPPLValidation = () => {
    // }

    const fetchZasilkyPPLNepodanezPohody = () => {
        try {
            axios.get(`${apiKeys.base}/apipohodaimege/getzasilkypplnepodanezpohody`).then((response) => {
                SetUserDataResult(response.data.result)
                SetUserData(response.data.data);
                SetUserNactiButton(true);
            }).catch((error) => {
                setError(error);
                console.log(error);
            });
        } catch (error) {
            setError({message: 'Not Found'});
        }

        // return data;
    }

    useEffect(() => {
        SetUserData(null);
        SetUserDataResult(false);
        SetUserNactiButton(false);

        // SetZasilkyKTiskuData(null);
        // SetZasilkyKTiskuDataResult(false);
        // SetZasilkyKTiskuButton(false);
        fetchZasilkyPPLNepodanezPohody();
        // fetchZasilkyPacketaTiskuzPohody();


    }, [])

    if (error) return `Error: ${error.message}`;
    //

    return (
        <Fragment>
            <div id="NactiZasilkyBlock">
                <div className="container">
                    <div className='row'>
                        {UserNactiButton ? (
                            <div className='col-12 col-centered'>
                                <form onSubmit={buttonZasilkyPacketaNepodanezPohody}>
                                    <button type='submit' className='button' onSubmit={buttonZasilkyPacketaNepodanezPohody}>
                                        Načti zásilky PPL z Pohody
                                    </button>
                                </form>
                            </div>
                        ) : (<></>)}
                    </div>
                </div>


                {UserData ? (
                    <div className="container">
                        <div className="row">
                            <h1 className="subheader">Zásilky pro PPL</h1>
                            <table id='tblZasilkyCheck' className="tblZasilky">
                                <thead>
                                <tr>
                                    <th>ř.</th>
                                    <th>Ověř</th>
                                    <th>Datum</th>
                                    <th>Číslo Zasilky</th>
                                    <th>Číslo Objednávky/VS</th>
                                    <th>Zákazník</th>
                                    <th>Email</th>
                                    <th>Telefon</th>
                                    <th>PSC</th>
                                    <th>Mesto</th>
                                    <th>Země</th>
                                    <th>Hmotnost</th>
                                    <th>Hodnota</th>
                                    <th>Dobírka</th>
                                    <th>Pobočka/Místo</th>
                                    <th>Služba</th>
                                </tr>
                                </thead>
                                <tbody>

                                {UserData.map((z) => (
                                    <Fragment key={z.number}>
                                        <tr key={z.number}>
                                            <td>{z.i}</td>
                                            <td>{z.valid}</td>
                                            <td>{z.datum}</td>
                                            <td>{z.numberOfPohoda}</td>
                                            <td>{z.number}</td>
                                            <td className="text-left text-nowrap">{z.firma}{" "}{z.name}{" "}{z.surname}
                                            </td>
                                            <td>{z.emailAddress}
                                                {/*<div className="errorNote">{z.error}</div>*/}
                                            </td>
                                            <td>{z.phoneNumber}</td>
                                            <td className="text-nowrap">{z.zip}</td>
                                            <td className="text-left text-nowrap">{z.city}</td>
                                            <td>{z.country}</td>
                                            <td>{z.weightKg}</td>
                                            <td>{z.value}</td>
                                            <td>{z.cod}</td>
                                            {/*<td>{z.codVarSymbol}</td>*/}
                                            <td>{z.pickupPointOrCarrier}</td>
                                            <td className="text-left text-nowrap">{z.dopravceSluzba}</td>
                                        </tr>
                                        {z.error && <tr>
                                        <td className="errorNote" colSpan="16">{z.error}</td>
                                        </tr>
                                        }
                                    </Fragment>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className='col-1 col-centered'>
                            <div className="loader_block loader"></div>
                        </div>
                    </div>
                )}

                <div className='row'>
                    <div className='col-3 col-centered'>
                        <form onSubmit={buttonZasilkyPPLzPohodyZapis}>
                            {UserDataResult === "ok" ? (
                                <button type='submit' className='button button_odesli'
                                        onSubmit={buttonZasilkyPPLzPohodyZapis}>
                                    {UserDataResult} - Přepiš Ověřené do PPL API
                                </button>
                            ) : null}
                        </form>
                    </div>
                </div>
                <p></p>
            </div>
        </Fragment>
    )
    //

}


export default ZasilkyPacketaList
